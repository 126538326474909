import logo from './logo.svg';
import './App.css';
import StickyHeadTable from './components/table';
import Homepage from './containers/Homepage';
function App() {
  return (
    <div className="App">
      <Homepage></Homepage>
    </div>

  );
}

export default App;
